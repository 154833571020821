<template>
  <li :class="{ visited: checkingVisit }" @click="clickHandle()">
    <h3 class="search-results__name sidebar-results__name">
      {{ item.name }}
    </h3>
    <p class="search-results__certificate sidebar-results__certificate" v-if="item.is_sertified">
      <img src="@/assets/images/svg/star.svg">
      С сертификатом
    </p>
    <div v-if="(item.phone_1 || item.phone_2) && (item.phone_1.length || item.phone_2.length)"
      style="display: flex; gap: 16px;">
      <p v-if="item.phone_1 || item.phone_1.length" class="search-results__phone sidebar-results__phone">
        {{ item.phone_1 }}
      </p>
      <p v-if="item.phone_2 || item.phone_2.length" class="search-results__phone sidebar-results__phone">
        {{ item.phone_2 }}
      </p>
    </div>
    <p class="search-results__address sidebar-results__address">
      {{ item.address }}
    </p>
    <div v-if="checkingVisit" class="search-results-badge__visibility sidebar-results-badge__visibility">
      <p>Просмотрено</p>
    </div>
    <div v-if="isAuthenticated" class="search-results__visibility sidebar-results__visibility">
      <img src="@/assets/images/svg/eye-open_outline.svg" v-if="item.is_show_on_map">
      <img src="@/assets/images/svg/eye-close_outline.svg" v-else>
    </div>
  </li>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SidebarListItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    clickHandle() {
      this.startLoading();

      try {
        this.setEntityId(this.item.id);
        this.setVisited(this.item.id);
        this.$store.dispatch('setActiveItem', this.item.id);
        this.$router.push(`/${this.layer}/${this.item.id}`);
      } catch (error) {
        this.$services.MessageService.error('Не удалось загрузить данные');
        console.error(error.Message, error.stack);
      } finally {
        this.stopLoading();
      }
    },
    ...mapActions('entity', ['setEntityId', 'setVisited']),
    ...mapActions('loader', ['startLoading', 'stopLoading'])
  },
  computed: {
    checkingVisit() {
      return this.visited.includes(this.item.id);
    },
    ...mapGetters('layer', ['layer']),
    ...mapGetters('entity', ['visited']),
    ...mapGetters('user', ['isAuthenticated'])
  }
}
</script>

<style lang="scss" scoped>
.sidebar-results {
  li {
    position: relative;
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E5E5E5;
    transition: background-color .3s ease;
    cursor: pointer;
  }

  &__list.visited {
    background-color: #EFEFEF;
  }

  &__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    padding-right: 30px;
  }

  &__certificate {
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: 5px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__certificate,
  &__address,
  &__phone {
    font-size: 16px;
    line-height: 20px;
    color: #8C8C8C;
  }

  &__visibility {
    position: absolute;
    top: 20px;
    right: 20px;

    img {
      width: 20px;
    }
  }
}

.sidebar-results-badge {
  &__visibility {
    position: absolute;
    top: 5px;
    right: 20px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #8C8C8C;
    }
  }
}
</style>
